/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  text: string;
  className: any;
  handleClick?: () => void; // Optional onChange prop
}

export const ButtonStrokeWhite = ({ text = "BUTTON TEXT", className, handleClick }: Props): JSX.Element => {
  return (
    <div className={`button-stroke-white ${className}`} onClick={handleClick}>
      <div className="text-wrapper">{text}</div>
    </div>
  );
};

ButtonStrokeWhite.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func
};
