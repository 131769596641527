import "./style.css";

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Event } from '../Event';

interface Event {
    date: Date;
    title: string;
    description: string;
}

interface Props {}

export const EventList = ({ }: Props): JSX.Element => {
    const [csvData, setCsvData] = useState<Event[]>([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        fetchCSVData();
        setIsLoading(false);
    }, []);

    function parseDate(input: string) {
        const parts = input.split('/');
        return new Date(+parts[2], +parts[1] - 1, +parts[0]);
    }

    function parseCSVDateToEvent(data: string) {
        const start = data.indexOf('",,') + 3;
        data = data.substring(start);

        let events: Event[] = [];
        const rows = data.split('\n')

        for (let i = 2; i < rows.length; i++) {
            const columns = rows[i].match(/([^,]*),(\d{2}\/\d{2}\/\d{4}),(.*)/);

            try {
                if (!columns || columns.length !== 4) throw new Error(rows[i]);
                    
                let event: Event = {
                    date: parseDate(columns[2]),
                    title: columns[1],
                    description: columns[3].replace(/"/g, '')
                }
                events.push(event);
            } catch (error) {
                console.error(`Error parsing event at index ${i} with object:`, rows[i]);
            }
        }
    
        return events;
    }

    const fetchCSVData = () => {
        const csvUrl = 'https://docs.google.com/spreadsheets/d/e/2PACX-1vSpntKjqwKBkB70Smp8tkeJ0gPrL5YQN99ioYvQhASv4XHiDSMdN2IWdJ1_yrsnxATUSwbEoXp7NIEO/pub?output=csv'; // Replace with your Google Sheets CSV file URL
        axios.get(csvUrl)
            .then((response) => {
                const csvData = response.data;
                setCsvData(parseCSVDateToEvent(csvData));
            })
            .catch((error) => {
                console.log(error);
            });
    }

    return (
        <div className='event-list'>
        {!isLoading && 
            csvData.sort((a, b) => a.date.getTime() - b.date.getTime())
                    .filter(event => {
                        const today = new Date();
                        return event.date.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0);
                    })
                   .map((event, index) => {

            return (
              <Event
                key={index}
                className="design-component-instance-node"
                day={event.date.getDate().toString()}
                month={event.date.toLocaleString('default', { month: 'short' })}
                text={event.description}
                title={event.title}
              />
            );
          })}
        </div>
      );
};