/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { ButtonStroke } from "../ButtonStroke";
import "./style.css";

import * as images from '../../assets/images/images';

interface Props {
  title: string;
  text: string;
}

export const Arrangement = ({
  title,
  text
}: Props): JSX.Element => {
  return (
    <div className="arrangement">
      <img src={images[title.split(' ')[0].toLowerCase()]} className="arrangement-image" />
      <div className="arrangement-content">
        <div className="arrangement-text">
          <div className="ontbijt">{title}</div>
          <p className="lorem-ipsum-dolor">{text}</p>
        </div>
        <ButtonStroke className="button-stroke-primary" text="aanvragen" />
      </div>
    </div>
  );
};

Arrangement.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string
};
