/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  text: string;
  pressing?: boolean;
  className: any;
  onClick?: () => void;
}

export const ButtonPrimary = ({ text = "BUTTON TEXT", pressing = false, className, onClick }: Props): JSX.Element => {
  return (
    <div className={`button-primary ${className}`} onClick={onClick}>
      <div className="BUTTON-TEXT">{text}</div>
    </div>
  );
};

ButtonPrimary.propTypes = {
  text: PropTypes.string,
  pressing: PropTypes.bool,
  onClick: PropTypes.func
};
