import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dropdownPoly from '../../../assets/images/dropdown_poly.png';
import "./style.css";

interface Props {
  options: string[];
  expanded?: boolean;
  className?: string;
  onChange: (event: string) => void;
  startSelected?: string;
}

export const Dropdown: React.FC<Props> = ({
  options,
  expanded = false,
  className = "",
  onChange,
  startSelected = ""
}) => {
  const [selected, setSelected] = useState(startSelected)

  useEffect(() => onChange(selected), [selected])

  return (
    <div className={`dropdown ${expanded ? 'expanded' : ''} ${className}`}>
      <select className="dropdown-select" value={selected} onChange={e => setSelected(e.target.value)}>
        <option disabled value={''}> -- Selecteer optie -- </option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  expanded: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
