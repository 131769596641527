/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  text: string;
  active: boolean;
  filled: boolean;
  long1: boolean;
  className: any;
  value?: string; // Optional value prop for controlled component
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Optional onChange prop
}

export const TextFieldWhite = ({ text = "Volledige naam", active, filled, long1, className, value = '', onChange }: Props): JSX.Element => {
  return (
    <input
      type="text"
      className={`text-field-white active-${active} long-${long1} ${className} volledige-naam filled-${value !== ''}`}
      value={value}
      onChange={e => {
        if (onChange != undefined) {
          onChange(e)
        }
      }}
      placeholder={text}
    />
  );
};

TextFieldWhite.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  filled: PropTypes.bool,
  long1: PropTypes.bool,
  className: PropTypes.any,
  value: PropTypes.string, // Adding prop type for value
  onChange: PropTypes.func, // Adding prop type for onChange
};
