/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import { TextFieldPrimary } from "../TextFieldPrimary";
import "./style.css";

interface Props {
  title: string;
  className: any;
  textFieldPrimaryText?: string;
  override?: JSX.Element;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void; // Optional onChange prop
  value?: string;
}

export const FormItem = ({
  title = "Item title",
  className,
  textFieldPrimaryText = '',
  onChange,
  value = '',
  override = (
    <TextFieldPrimary
      active={false}
      className="text-field-primary-instance"
      filled={false}
      long1={false}
      onChange={onChange}
      text={textFieldPrimaryText}
      value={value}
    />
  ),
}: Props): JSX.Element => {
  return (
    <div className={`form-item ${className}`}>
      <div className="item-title">{title}</div>
      {override}
    </div>
  );
};

FormItem.propTypes = {
  title: PropTypes.string,
  textFieldPrimaryText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
};
