import React, { useState } from "react";
import { ButtonPrimary } from "../ButtonPrimary";
import { DatePicker } from "./components/DatePicker";
import { Dropdown } from "./components/Dropdown";
import { FormItem } from "./components/FormItem";
import PropTypes from "prop-types";
import "./style.css";
import { ButtonStroke } from "../ButtonStroke";


interface Props {
  selectedArrangement?: string;
  selectedEvent?: string;
  arrangementOptions?: any;
  eventTypeOptions?: any;
  onClick: (button: string) => void;
}

export const ArrangementPopup: React.FC<Props> = ({ selectedArrangement, selectedEvent, arrangementOptions, eventTypeOptions, onClick }): JSX.Element => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [numberOfGuests, setNumberOfGuests] = useState('');
  const [details, setDetails] = useState('');
  const [eventType, setEventType] = useState('');
  const [arrangement, setArrangement] = useState('');
  const [date, setDate] = useState('');

  const handleFullNameChange = (event) => { setFullName(event.target.value); };
  const handleEmailChange = (event) => { setEmail(event.target.value); };
  const handleNumberOfGuestsChange = (event) => { setNumberOfGuests(event.target.value); };
  const handleDetailsChange = (event) => { setDetails(event.target.value); };
  const handleEventChange = (event) => { setEventType(event) };
  const handleArrangementChange = (event) => { setArrangement(event); };
  const handleDateChange = (event) => { setDate(event);  };

  function isNotEmpty(str) {
    return str.trim().length > 0;
  }

  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  function submitForm() {
    const emailBody = `
      Hallo,
      
      Ik zou graag een "${arrangement}"-arrangement willen aanvragen met de volgende details:

      |  Naam: ${fullName},
      |  Email: ${email},
      |  Type evenement: ${eventType},
      |  Arrangement: ${arrangement},
      |  Date and time: ${date},
      |  Aantal gasten: ${numberOfGuests},
      |  Details: ${details}
      
      Met vriendelijke groeten,
      ${fullName}
    `;

    const subject = encodeURIComponent("Arrangement aanvragen");
    const body = encodeURIComponent(emailBody);
    window.open(`mailto:${'horeca@kampong.nl'}?subject=${subject}&body=${body}`, '_blank');


  }

  return (
    <div className="arrangement-popup" onClick={stopPropagation}>
      <div className="arrangement">
        ARRANGEMENT
        <br />
        AANVRAGEN
      </div>
      <div className="div-2">
        <FormItem
          className="form-item-instance"
          textFieldPrimaryText="Voor- en achternaam"
          title="Naam"
          onChange={handleFullNameChange}
          value={fullName}
        />
        <FormItem
          className="design-component-instance-node"
          textFieldPrimaryText="person@example.com"
          title="E-mailadres"
          onChange={handleEmailChange}
          value={email}
        />
        <FormItem
          className="form-item-2"
          override={
            <Dropdown className="design-component-instance-node-2" startSelected={selectedEvent} expanded={false} options={eventTypeOptions} onChange={handleEventChange} />
          }
          title="Type evenement"
        />
        <FormItem
          className="form-item-3"
          override={
            <Dropdown className="design-component-instance-node-2" startSelected={selectedArrangement} expanded={false} options={arrangementOptions} onChange={handleArrangementChange}/>
          }
          title="Arrangement"
          onChange={handleArrangementChange}
        />
        <div className="form-item-4">
          <div className="item-title-2">Datum en tijd</div>
          <DatePicker className="design-component-instance-node-2" handleChange={handleDateChange}/>
        </div>
        <FormItem
          className="form-item-5"
          title="Aantal gasten"
          textFieldPrimaryText="Hoeveel mensen komen er?"
          onChange={handleNumberOfGuestsChange}
          value={numberOfGuests}
        />
        <FormItem
          className="form-item-6"
          title="Details / Diëetwensen"
          textFieldPrimaryText="Beschrijf hier wat je in gedachten hebt..."
          onChange={handleDetailsChange}
          value={details}
        />
        <div className="buttons">
          <div onClick={() => onClick('back')}>
            <ButtonStroke className="button-stroke-instance" text="TERUG" />
          </div>
          <ButtonPrimary className="button-primary-instance" text="VERSTUREN" onClick={submitForm} />
        </div>
      </div>
    </div>
  );
};

ArrangementPopup.propTypes = {
  selectedArrangement: PropTypes.string,
  selectedEvent: PropTypes.string,
  arrangementOptions: PropTypes.any,
  eventTypeOptions: PropTypes.any,
  onClick: PropTypes.func.isRequired
};