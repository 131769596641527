/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

interface Props {
  title: string;
  text: string;
  month: string;
  day: string;
  className: any;
}

export const Event = ({
  title,
  text,
  month,
  day,
  className,
}: Props): JSX.Element => {
  return (
    <div className={`event ${className}`}>
      <div className="event-date">
        <div className="sep">{month}</div>
        <div className="element">{day}</div>
      </div>
      <div className="event-text">
        <div className="lorem-ipsum-dolor-2">{title}</div>
        <div className="p">{text}</div>
      </div>
    </div>
  );
};

Event.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  month: PropTypes.string,
  day: PropTypes.string,
};
