import "./style.css";

import React, { useState } from 'react';
import { TextFieldWhite } from "../../components/TextFieldWhite";
import { ButtonStrokeWhite } from "../../components/ButtonStrokeWhite"; 


export const WorkAt = () => {
  // State for storing input field values
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  // Handlers for input field changes
  const handleFullNameChange = (event) => {
    setFullName(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  function is_empty(string_to_check) {
    return string_to_check === null || string_to_check.trim() === ""
  }

  function handleSubmit() {
    const emailBody = `
      Hoi,
        
      Ik zag het formulier op jullie website en zou graag willen solliciteren met de volgende details:

      |  Naam: ${fullName},
      |  Email: ${email},
      |  Details: ${message}
        
      Met vriendelijke groeten,
      ${fullName}
    `;

    const subject = encodeURIComponent("Sollicitatie");
    const body = encodeURIComponent(emailBody);

    window.open(`mailto:${'horeca@kampong.nl'}?subject=${subject}&body=${body}`, '_blank');
}

  return (
    <div className="solliciteren-form">
      <div className="solliciteren-form-2">
        <TextFieldWhite
          text="Volledige naam"
          active={true}
          filled={fullName !== ''}
          long1={false}
          className="text-field-white-instance"
          value={fullName}
          onChange={handleFullNameChange}
        />
        <TextFieldWhite
          text="E-mailadres"
          active={true}
          filled={email !== ''}
          long1={false}
          className="text-field-white-instance"
          value={email}
          onChange={handleEmailChange}
        />
        <TextFieldWhite
          text="Typ hier je bericht..."
          active={true}
          filled={message !== ''}
          long1={true}
          className="text-field-white-instance"
          value={message}
          onChange={handleMessageChange}
        />
        <ButtonStrokeWhite text="VERSTUREN" className="design-component-instance-node" handleClick={handleSubmit}/>
      </div>
    </div>
  );
};