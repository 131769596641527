[{
    "text": "Begin de dag goed met een overheerlijk ontbijtbuffet van Nr 14. (Te boeken vanaf 10 personen)",
    "title":"Ontbijt",
    "items": [
      { "name": "Nr 14 goed start ontbijt – €4,95 p.p.", "description": "Croissant | pistolet | kaas | ham | gekookt ei | zoetwaar | koffie of thee"}
    ]
  },
  {
    "text":"Team Lunch in buffetvorm, onder het genot van een goed belegd broodje de strategie of nabespreking van jullie wedstrijd.",
    "title":"Lunch",
    "items": [
      { "name": "Lunch 1 - €4,95 p.p.", "description":"Bruine pistoletjes (2 per persoon) | Jonge kaas | Kipfilet | Komkommer en tomaat | Zoetwaren"},
      { "name": "Lunch 2 - €9,95 p.p.", "description":"Diverse afbakbroodjes (2 per persoon) | Jonge kaas | Kipfilet | Serranoham | Gekookte eieren | Komkommer en tomaat | Zoetwaren"},
      { "name": "Lunch 3 - €13,95 p.p.", "description":"- Diverse afbakbroodjes (2 per persoon) | Jonge kaas | Kipfilet | Serranoham | Gekookte eieren | Buffelmozzarella | Avocado | Komkommer en tomaat | Zoetwaren"}
    ],
    "extra": [
      { "name": "Dagsoep - €4,95" },
      { "name": "Melk, just d'orange en appelsap - €2,50" },
      { "name": "Vers gebakken croissants - €1,95" }
    ]
  },
  {
    "text":"Lekker genieten van een hapje, een drankje en een borrelplankje? Bij Nummer 14 kun je een heerlijk borrelarrangement aanvragen. Santé!",
    "title":"Borrel",
    "items": [
      { "name": "Borrelplank (koud) - €7,50 p.p.", "description":"Gevarieerde borrelplank vlees en vega"},
      { "name": "Borrelplank (warm) - €7.50 p.p.", "description":"Gevarieerde borrelplank vlees, vis en vega"},
      { "name": "Bittergarnituur (klein) - €14.00" },
      { "name": "Bittergarnituur (groot) - €25.00" }
    ]
  },
  {
    "text":"Voor de echte smulpapen bieden we bij Nummer 14 ook een diner-arrangement. Er is een hoop om uit te kiezen, zowel vegetarisch als non-vega!",
    "title":"Diner (10+ personen)",
    "items": [
      { "name": "Keuze van de chef - €9.95 p.p."},
      { "name": "Team friettafel - €6.50 p.p.", "description":"friet + snack naar keuze incl. sauzen"},
      { "name": "Spaghetti bolognaise - €9.95 p.p." },
      { "name": "Lasagna - €9.95 p.p." },
      { "name": "Pasta kip pesto - €9.95 p.p." },
      { "name": "Nasi - €9.95 p.p." }
    ]
  },
  {
    "text":"Zin in een heelijke zomerse barbecuesessie? Kies onze heerlijke BBQ-optie!",
    "title":"BBQ",
    "items": [
      { "name": "Barbeque menu - €12.50 p.p.", "description": "100 gram kipsaté | 100 gram (runder)hamburger | 80 gram varkensworst | Stokbrood & sauzen (cocktail, knoflook en satésaus)"}
    ],
    "extra": [
      { "name": "Slices watermeloen - €1.50 p.p." },
      { "name": "Pasta salade met groenten - €2.50 p.p." },
      { "name": "Groentespies - €2.95 p.p." },
      { "name": "Patat - €3.00 p.p." },
      { "name": "Groene salade - €2.50 p.p.", "description": "Romige dressing | croutons | Parmezaanse kaas en pijnboompitten" },
      { "name": "75 gram biefstuk - €3.95 p.p." },
      { "name": "50 gram garnaalspies - €2.95 p.p." }
    ]
  },
  {
    "text":"Overdag zijn er diverse ruimtes beschikbaar voor kleine en grote groepen. Als hospitality partner verwelkomen wij jou en jouw gezelschap en zorgen we voor koffie met lekkers, lunch, borrel of diner.",
    "title":"Vergaderen",
    "items": [
      { "name": "Warming up - €6.95 p.p.", "description": "Warme drank tijdens binnenkomst" },
      { "name": "Één-tweetje - €11.95 p.p.", "description": "koffie/thee bij binnenkomst en bij rust (van de vergadering/training) koffie/water/fris" },
      { "name": "Cooling down - €16.95 p.p.", "description": "Gedurende het dagdeel warme dranken, diverse soorten water, frisdrank en sportdrank" },
      { "name": "3 punten - €24.95 p.p.", "description": "Warme dranken bij binnenkomst, bij rust frisdrank/warme drank, na de activiteit/training een uitgebreid lunchbuffet incl. zuivel en sappen" },
      { "name": "Derde helft - €24.95 p.p.", "description": "Vrije keuze uit het dranken assortiment inclusief bittergarnituur" }
    ],
    "extra": [
      { "name": "Fruit, Banaan/appels - €0,95 p.p." },
      { "name": "Muesly + yoghurt - €2,95 p.p." },
      { "name": "Smoothies - €3,25 p.p." },
      { "name": "Vers gesneden fruit - €4,95 p.p." }
    ]
  },
  {
    "text":"Wil je iets organiseren dat hier niet tussen staat? Beschrijf zelf wat je in gedachten hebt, en dan kijken we wat we voor je kunnen doen!",
    "title":"Ander arrangement"
  },
  {
    "text":"Beleef een actieve dag die draait om vitaliteit, gezondheid of strategie. Vraag ons naar de mogelijkheden.",
    "title":"Sportclinic"
  }
]