import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import arrangementsData from '../../assets/arrangements.json';
import { ButtonStroke } from '../ButtonStroke';
import { ButtonPrimary } from '../ButtonPrimary';
import "./style.css";

interface Props {
  title: string;
  onClick: (button: string) => void;
}

interface Item {
  title: string;
  text: string;
  items?: { name: string; description?: string }[];
  extra?: { name: string; description?: string }[];
}

export const InfoPopup: React.FC<Props> = ({ title, onClick }): JSX.Element => {
  const extra_description_vergaderen = "Arrangementen zijn per dagdeel per persoon exclusief btw. Mocht er interesse zijn om na afloop te dineren, te barbecueën of een Poké Bowl to go, vraag dan naar de mogelijkheden."

  const [item, setItem] = useState<Item | null>(null);

  useEffect(() => {
    // Find the item with the matching title
    const foundItem = arrangementsData.find(arrangement => arrangement.title === title);

    // Set the found item to the state
    if (foundItem) setItem(foundItem);
  }, [title]);

  const [mode, setMode] = React.useState('desktop');

  React.useEffect(() => {
    const handleResize = () => {
      setMode(window.innerWidth < 950 ? 'mobile' : 'desktop');
    };

    window.addEventListener('resize', handleResize);
    handleResize();
  }, []);

  // Render the item
  return (
    <div className="info-popup" data-collection-1-mode={mode}>
      <div className="info-text-area">
        {item ? (
          <>
            <div className='titel'>{item.title}</div>
            <div className='korte-beschrijving'>
              {item.text}
              {item.title == "Vergaderen" && <div>{extra_description_vergaderen}</div>}
            </div>
            <div className='details-en-prijzen'>
              {item.items && item.items.map((subItem, index) => (
                <div className='item' key={index}>
                  <span className='span'>{subItem.name} <br/> </span>
                  {subItem.description && <span className="text-wrapper-2">{subItem.description}</span>}
                </div>
              ))}
              {item.extra && <span className="text-wrapper-3">extra: <br/></span>}
              {item.extra && item.extra.map((extraItem, index) => (
                <>
                  <div className='sub-item' key={index}>
                    <span className='span'>{extraItem.name} <br/> </span>
                    {extraItem.description && <span className="text-wrapper-2">{extraItem.description}</span>}
                  </div>
                </>
              ))}
            </div>
          </>
        ) : null}
      </div>
      <div className="info-button-area">
        <div onClick={() => onClick('back') }>
          <ButtonStroke className="design-component-instance-node" text="TERUG" />
        </div>
        <div onClick={() => onClick('arrange')}>
          <ButtonPrimary className="design-component-instance-node" text="AANVRAGEN" />
        </div>
      </div>
    </div>
  );
};

InfoPopup.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};