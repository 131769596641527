/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "./style.css";

interface Props {
  time?: boolean;
  className: any;
  handleChange: (dateTimeRange: string) => void;
}

export const DatePicker = ({ time = true, className, handleChange }: Props): JSX.Element => {
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    switch (id) {
      case 'date':
        setDate(value);
        break;
      case 'start':
        setStartTime(value);
        break;
      case 'end':
        setEndTime(value);
        break;
      default:
        break;
    }
  };

  useEffect(() => handleChange(`Op ${date} van ${startTime} tot ${endTime}`), [date, startTime, endTime])

  return (
    <div className="picker">
      <div className={`date-picker ${className}`}>
        <input id="date" type="date" className="text-wrapped-2 date-bar-text date-bar" onChange={handleInputChange}></input>
      </div>
      {time && (
          <div className={`date-picker time-picker ${className}`}>
            <p>Van</p>
            <input id="start" type="time" className="text-wrapped-2 date-bar-text date-bar" min="09:00" max="18:00" required onChange={handleInputChange}/>
            <p>Tot</p>
            <input id="end" type="time" className="text-wrapped-2 date-bar-text date-bar" min="09:00" max="18:00" required onChange={handleInputChange}/>          
          </div>
      )}
    </div>
  );
};

DatePicker.propTypes = {
  time: PropTypes.bool,
  handleChange: PropTypes.func.isRequired
};
