import "./style.css";

import React, { useState, useEffect, useRef } from "react";
import { Arrangement } from "../../components/Arrangement";
import { ButtonStrokeWhite } from "../../components/ButtonStrokeWhite";
import { ButtonWhite } from "../../components/ButtonWhite";
import { Event } from "../../components/Event";
import { TextFieldWhite } from "../../components/TextFieldWhite";
import { WorkAt } from "../../components/WorkAt/WorkAt";
import { ArrangementPopup } from "../../components/ArrangementModal/ArrangementPopup";
import arrangements from "../../assets/arrangements.json";
import { EventList } from "../../components/EventList";
import { InfoPopup } from "../../components/InfoPopup/InfoPopup";

import menuImage from '../../assets/images/nr14_menu.png';
import about from '../../assets/images/about.jpg'
import vacFloorManager from '../../assets/images/Vacature Floormanager-1.png';

export const Main = (): JSX.Element => {

  const [selectedArrangement, setSelectedArrangement] = useState('');
  const [selectedEvent, setSelectedEvent] = useState('');
  const [arrangementStep, setArrangementStep] = useState(0);

  const events = ['Particulier', 'Zakelijk'];

  const popupRef = useRef<HTMLDivElement | null>(null);

  const handlePopupClick = (event) => {
    if (!(popupRef.current && popupRef.current !== event.target && popupRef.current.contains(event.target))) {
      setSelectedArrangement('');
      setSelectedEvent('');
      setArrangementStep(0);
    }
  };

  const handlePopupButtonClick = (button) => {
    if (button === 'back') {
      setArrangementStep(arrangementStep > 0 ? arrangementStep - 1 : 0);
    } else if (button === 'arrange') {
      setArrangementStep(2);
    }
  }

  useEffect(() => {
    if (arrangementStep > 0) { document.addEventListener('mousedown', handlePopupClick); }
  }, [arrangementStep]);

  const navigatePopup = () => {
    if (arrangementStep === 1 && selectedArrangement != '') {
      return <InfoPopup title={selectedArrangement} onClick={handlePopupButtonClick} />;
    } else if (arrangementStep === 2) {
      if (selectedArrangement != '') {
        return <ArrangementPopup arrangementOptions={arrangements.map(arrangement => arrangement.title)} selectedArrangement={selectedArrangement} eventTypeOptions={events} onClick={handlePopupButtonClick} />
      } else if (selectedEvent != '') {
        return <ArrangementPopup arrangementOptions={arrangements.map(arrangement => arrangement.title)} eventTypeOptions={events} selectedEvent={selectedEvent} onClick={handlePopupButtonClick} />;
      } else {	
        return <ArrangementPopup arrangementOptions={arrangements.map(arrangement => arrangement.title)} eventTypeOptions={events} onClick={handlePopupButtonClick} />;
      }
    } else {
      setSelectedArrangement('');
      setSelectedEvent('');
      setArrangementStep(0);
    }
  }

  return (
    <div className="main">
      { arrangementStep > 0 &&
        <div className="popup-wrapper" ref={popupRef}>
          { navigatePopup() }         
        </div>
      }
      <div className="hero">
        <div className="hero-content">
          <div className="hero-text">
            <p className="text-wrapper-2">Jouw event bij Nummer14 op <br /> SV Kampong</p>
            <p className="text-wrapper-3">
              Wist je dat het mogelijk is om te vergaderen of te borrelen op Kampong? Overdag zijn er diverse ruimtes
              beschikbaar voor kleine en grote groepen. Als hospitality partner verwelkomen wij jou en jouw gezelschap
              en zorgen we voor koffie met lekkers, lunch, borrel of diner.
            </p>
          </div>
          <div className="div-2">
            <div onClick={() => { setSelectedEvent('Als team'); setArrangementStep(2)}}>
              <ButtonWhite className="design-component-instance-node" text="Als team" />
            </div> 
            <div onClick={() => { setSelectedEvent('Zakelijk'); setArrangementStep(2)}}>
              <ButtonStrokeWhite className="design-component-instance-node" text="Zakelijk" />
            </div>
          </div>
        </div>
      </div>
      <div className="div-wrapper">
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-4">onze arrangementen</div>
            <p className="text-wrapper-5">
              Heerlijk ontbijten of lunchen bij Nummer 14? Of toch liever een dinertje? Vraag een van onze arrangementen aan
              en geniet met je team, collega&#39;s, of vriendengroep van een hapje en een drankje in een van onze
              sfeervolle ruimtes.
            </p>
          </div>
          <div className="div-5">
            {arrangements.map((arrangement, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedArrangement(arrangement.title)
                  setArrangementStep(1);
                }}>
                <Arrangement
                  key={index}
                  title={arrangement.title}
                  text={arrangement.text}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="div-6">
        <div className="menu-image-container">
          <img src={menuImage} className="menu-image"/>
        </div>
        <div className="menu-content">
          <div className="div-7">
            <div className="text-wrapper-6">ontdek ons menu</div>
            <p className="text-wrapper-7">
              Je kunt bij Nummer 14 van alles te eten krijgen. Van onze eeuwig lekkere classics, tot een wekelijks wisselend
              menu voor de avonturiers onder ons. Download de menukaart en bekijk welke heerlijkheden er bij Nummer 14 te
              krijgen zijn.
            </p>
          </div>
          <div className="div-2">
            <a href={menuImage} target="_blank">
              <ButtonWhite className="design-component-instance-node" text="download nu" />
            </a>
          </div>
          <div className="menu-socials">
            <p className="div">Houd onze socials in de gaten voor wisselende gerechten en meer:</p>
            <div className="frame">
              <a className="social-icon" href="https://www.facebook.com/NummerVeertienKampong/" target="_blank" rel="noopener noreferrer">
                <img src={require("..\\..\\assets\\images\\facebook.svg")} alt="Facebook" />
              </a>
              <a className="social-icon" href="https://www.instagram.com/nr14kampong/" target="_blank" rel="noopener noreferrer">
                <img src={require("..\\..\\assets\\images\\instagram.svg")} alt="Instagram" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="div-wrapper">
        <div className="div-3">
          <div className="div-4">
            <div className="text-wrapper-4">binnenkort bij Nummer 14</div>
            <p className="text-wrapper-5">
              Bij Nummer 14 is er altijd wat te doen. Bekijk hieronder de events die de komende tijd bij ons plaats zullen
              vinden. Zien we jou binnenkort op een van deze evenementen?
            </p>
          </div>
          <EventList />
        </div>
      </div>
      <div className="div-6">
        <div className="solliciteren-content">
          <div className="solliciteren-text">
            <div className="div-7">
              <div className="text-wrapper-6">Werken bij Nummer 14?</div>
              <p className="text-wrapper-7">
                Kampong is de grootste omnisportvereniging van Nederland. Daarom is er altijd wat leuks te doen op de
                club. Bij Nummer 14 tappen we dagelijks biertjes, zetten we verse cappuccino&#39;s en beleggen we royale
                broodjes voor onze leden, bestuurders en gasten. Lijkt het jou daarom leuk om in de horeca te werken in
                een gezellig team vol andere Kampongers? Vul het formulier hieronder in!
              </p>
            </div>
            <div className="div-2">
              <a className="vacature" href={vacFloorManager} target="_blank">
                <ButtonWhite className="design-component-instance-node" text="Vacature Floormanager" />
              </a>
            </div>
          </div>
            <WorkAt />
        </div>
      </div>
      <div className="about">
        <div className="about-content">
          <div className="about-text">
            <div className="about-text-header">
              <div className="over-ons">OVER ONS</div>
              <p className="sinds-verzorgt">
                Sinds 2011 verzorgt Nummer14 met groot enthousiasme de horeca bij SV Kampong. Als
                huismeester is het ons doel om elke bezoeker en lid van Kampong, ongeacht leeftijd, te voorzien van een
                passende maaltijd, variërend van gezonde opties tot de geliefde bittergarnituur voor na de wedstrijd.{" "}
                <br />
                <br />
                Wij vinden het essentieel om een divers aanbod te hebben, zodat er voor ieder wat wils is, of het nu gaat
                om gezonde, minder gezonde of vega(n) opties. Gastvrijheid staat bij ons hoog in het vaandel; we streven
                ernaar om iedereen zich thuis te laten voelen en optimaal te genieten van hun tijd bij Kampong. Heb je
                vragen of suggesties? Wij staan altijd open voor uw feedback en horen graag van je!
              </p>
            </div>
          </div>
          <div className="about-image-container">
            <img className="about-image" src={about} alt="About NR14" />
          </div>
        </div>
      </div>
    </div> 
  );
};
